import $ from 'jquery';
import 'bootstrap';
import 'parsleyjs';
import  Cookies from 'js-cookie';
import "magnific-popup";
import 'jquery-match-height'
import AOS from 'aos';
import Swiper from 'swiper';
import 'bootstrap-select';
import 'simplebar';
// import 'typer-dot-js';
import 'sticky-sidebar/dist/jquery.sticky-sidebar';
import 'jquery.panzoom';
import './vendor/jquery-ui.js';
import './vendor/jquery.nice-select.min.js';
import './vendor/filter.js';

window.$ = window.jQuery = $;

import './vendor/multiselect.js';



let Page = Page || {};
(function($) {

    /* ---------------------------------------------
        Global Variables
    --------------------------------------------- */
    var $window = $(window),
        $html = $('html'),
        $body = $('body');
    
    /* ---------------------------------------------
        APP document Ready
    --------------------------------------------- */
    Page.documentOnReady = {
        init: function() {
            Page.initialize.init();
        }
    };
    
    /* ---------------------------------------------
        Page document Load
    --------------------------------------------- */
    Page.documentOnLoad = {
        init: function() {
            Page.initialize.preloader();
        }
    };
    
    /* ---------------------------------------------
        APP document Resize
    --------------------------------------------- */
    Page.documentOnResize = {
        init: function() {
            var t = setTimeout(function() {
            }, 250);
        }
    };
    
    /* ---------------------------------------------
        Scripts initialization
    --------------------------------------------- */
    $(document).ready( Page.documentOnReady.init);
    $window.on('load', Page.documentOnLoad.init);
    $window.on('resize', Page.documentOnResize.init);
    
    /* ------------------------------------------------------------------------------------------------------------------
        #############################################################################################################
    ------------------------------------------------------------------------------------------------------------------ */

    Page.initialize = {
        init: function() {
            Page.initialize.matchHeight();
            Page.initialize.aos();
            Page.initialize.cookie();
            Page.initialize.header();
            Page.initialize.innerLinks();
            Page.initialize.formValidation();
            Page.initialize.magnificPopup();
            Page.initialize.swiper();
            Page.initialize.gridMansory();
            Page.initialize.sticky();
            Page.initialize.multiSelect();
            Page.initialize.calc();
            Page.initialize.niceSelect();
            Page.initialize.mapCenter();
            Page.initialize.customSelect();
            Page.initialize.customSelectWidth();
        },
        customSelect: function () {

            //helper div - calc avb width
            $('<div class="container-fluid"><div class="row"><div class="col-24 col-lg-20 offset-lg-2"><div id="widthHelper"></div></div></div></div>').appendTo($body);

            /*$('.custom-dropdown-triger').on('click', function () {
                $(this).parent().toggleClass('open');
            });*/

            // hide dropdown click outside
            $(document).click(function (e) {
                if (!$(e.target).parents().is('.custom-dropdown') && !$(e.target).is('.custom-dropdown-triger')) {
                    $('.dropdown-select.open').removeClass('open');
                    $('.filter-link').removeClass('open');
                }
            });

            // dissmiss dropdown btn
            $('.hide-dropdown').on('click', function () {
                $('.dropdown-select.open').removeClass('open');
                $('.filter-link').removeClass('open');
            });
        },

        customSelectWidth: function () {
            var $widthCol = $('#widthHelper').width();
            $('.custom-dropdown').css('max-width', $widthCol);
        },
        niceSelect: function () {
            if ($('select:not(.multiselect)').length > 0) {
                $('select:not(.multiselect)').niceSelect();
            }

            $('.custom-dropdown-triger').on('click', function () {
                $(this).parent().toggleClass('open');
            });

            // hide dropdown click outside
            $(document).click(function (e) {
                if (!$(e.target).parents().is('.custom-dropdown') && !$(e.target).is('.custom-dropdown-triger')) {
                    $('.dropdown-select.open').removeClass('open');
                    $('.filter-link').removeClass('open');
                }
            });

            // dissmiss dropdown btn
            $('.hide-dropdown').on('click', function () {
                $('.dropdown-select.open').removeClass('open');
                $('.filter-link').removeClass('open');
            });
        },
        /* ---------------------------------------------
                    mapCenter
                --------------------------------------------- */
        mapCenter: function () {

            if ($('#mapCenter').length > 0) {
                $('svg [id*="level"]').each(function() {
                    var pathId = $(this).attr('id');
                    var obj = $.grep(theme.mapCenter, function (obj) {
                        return obj.placeId == pathId;
                    })[0];
                    if (pathId && !$.isEmptyObject(obj)) {
                        $(this).attr("title", obj.placeName);
                    }
                });
                // init bs tooltip
                $('[data-toggle="tooltip"]').tooltip();
                $('[id*="level"]').tooltip();

                // init panzoom
                var panzoomLayers = $('.map-center');
                var panzoom = panzoomLayers.find('.svg-layers').panzoom({
                    //startTransform: "scale(0.8)",
                    increment: 0.2,
                    minScale: 0.5,
                    maxScale: 3,
                    disablePan: false,
                    $zoomIn: panzoomLayers.find(".zoom-in"),
                    $zoomOut: panzoomLayers.find(".zoom-out"),
                    $zoomRange: panzoomLayers.find(".zoom-range"),
                    $reset: panzoomLayers.find(".zoom-reset"),
                    onPan: function () {
                        $('.tab-pane .map-tooltip').fadeOut();
                    }
                });

                panzoomLayers.find(".zoom-range").on("input", function() {
                    panzoom.panzoom("setMatrix", [ parseFloat($(this).val()), 0, 0, parseFloat($(this).val()), 0, 0 ]);
                });

                $('[id*="level"]').on('mousedown touchstart', function (e) {
                    e.stopImmediatePropagation();
                });

                // change #mapCenter-switch select
                $('#mapCenter-switch').on('change', function (e) {
                    //show tab id
                    var levelId = $(this).val();
                    $('a[href="#' + levelId + '"]').tab('show');

                    //reset panzoom
                    panzoom.panzoom("setMatrix", [  0.8, 0, 0,  0.8, 0, 0 ]);

                    // hide map tooltip
                    $('svg [id*="level"]').removeClass('active');
                    $('.tab-pane .map-tooltip').fadeOut();
                });

                $('svg [id*="level"]').on('click', function () {
                    $('svg [id*="level"]').removeClass('active');

                    $(this).addClass('active');
                    var pathId = $(this).attr('id');
                    var obj = $.grep(theme.mapCenter, function (obj) {
                        return obj.placeId == pathId;
                    })[0];

                    if (pathId && !$.isEmptyObject(obj)) {
                        var template = '<div class="tolttip-logo">' +
                            '<img src="' + obj.image + '"/></div>' +
                            '</div>' +
                            '<div class="tooltip-category">' +
                            '<p>' + obj.category.join(", ") + '</p>' +
                            '</div>' +
                            '<div class="tooltip-info">' +
                            '<p>' + obj.site + '</p>' +
                            '<p>' + obj.phone + '</p>' +
                            '</div>';

                        $('.tab-pane .map-tooltip .map-tooltip-content').html(template);
                        $('.tab-pane .map-tooltip').fadeIn();
                    } else {
                        $('.tab-pane .map-tooltip').fadeOut();
                    }
                });


                // click close map-tooltip
                $('.map-tooltip-close').on('click', function () {
                    $(this).parent().fadeOut();
                    $('svg [id*="level"]').removeClass('active');
                });

                //legend map
                var $checkboxesLegnd = $('#filter-legend input');
                $('#clear-legend').on('click', function () {
                    $checkboxesLegnd.prop('checked', false).change();
                    $('.layer-legend *').removeClass('show');
                });

                $checkboxesLegnd.change(function () {
                    $('.tab-pane.active .layer-legend *').removeClass('show');

                    $checkboxesLegnd.each(function (i, elem) {
                        if (elem.checked) {
                            $('.tab-pane.active .layer-legend [id*="' + elem.value + '"]').addClass('show');
                        }
                    });
                });

                $('#mapCenter-switch').on('change', function (e) {
                    $checkboxesLegnd.prop('checked', false).change();
                });


                //
                // if url has params
                //
                function getParam(name) {
                    return (location.search.split(name + '=')[1] || '').split('&')[0];
                }

                var $paramLevel = getParam('level');
                var $paramLegend = getParam('legend');
                var $parampLocation = getParam('location');

                if ($paramLevel && $parampLocation) {
                    // change tab
                    $('a[href="#level-' + $paramLevel + '"]').tab('show');

                    // update niceselect instance
                    $("#mapCenter-switch").val('level-' + $paramLevel).change();
                    $('select').niceSelect('update');

                    // trigger event
                    $('svg [id="' + $parampLocation + '"]').trigger('click').addClass('active');
                }

                //
                //show on map link
                //
                $('body').on('mouseenter', '.show-on-map', function (e) {
                    var show = $(this).attr('data-show-on-map');
                    // trigger click event
                    $('svg *').tooltip('hide').removeClass("hover");
                    $('svg [id="' + show + '"]').addClass("hover").tooltip('show');
                });
                $('body').on('mouseleave', '.show-on-map', function (e) {
                    $('svg *').tooltip('hide').removeClass("hover");
                });
                $('body').on('click', '.show-on-map', function (e) {
                    // scroll to map
                    $('html, body').animate({
                        scrollTop: $('#mapCenter').offset().top - 82
                    }, 800);

                    //show tab id
                    var show = $(this).attr('data-show-on-map');
                    console.log($(this));
                    $('a[href="#' + show.split('n')[0] + '"]').tab('show');

                    // update niceselect instance
                    $("#mapCenter-switch").val(show.split('n')[0]).change();
                    $('select').niceSelect('update');

                    //reset panzoom
                    panzoom.panzoom("setMatrix", [  0.8, 0, 0,  0.8, 0, 0 ]);
                   

                    // trigger click event
                    $('svg [id="' + show + '"]').trigger('click').addClass('active');
                });
            }


            // Filter results
            if ($('#list-shops').length > 0) {
                var $filterCheckboxes = $('#filter-checboxes input');
                var $levelCheckboxes = $('#level-checboxes input');

                // check if filter mapCenter
                if ($('#list-shops').attr('data-filterBy')) {
                    var data_filter = $('#list-shops').attr('data-filterBy');
                    theme.mapCenter = theme.mapCenter.filter(function (el) {
                        return el[data_filter].length
                    });
                }

                // autocomplete
                $('#autocpleteForm').on('submit', function (e) {
                    e.preventDefault();
                });

                if ($('input#autocomplite').length > 0) {
                    var dataComplete = theme.mapCenter.map(function (value, key) {
                        return {
                            label: value.placeName + " " + value.category,
                            value: value.placeName,
                            data: {
                                title: value.placeName,
                                image: value.image,
                                category: value.category,
                                placeId: value.placeId
                            }
                        }
                    });

                    $("input#autocomplite").autocomplete({
                        source: dataComplete,
                        close: function (event, ui) {
                            $("input#autocomplite").trigger('keyup');
                        }
                    }).data("ui-autocomplete")._renderItem = function (ul, item) {
                        return $("<li>").data("ui-autocomplete-item", item).append("<a class='show-on-map' data-show-on-map='"+ item.data.placeId +"'>" + item.data.title + "</a>").appendTo(ul);
                    };
                }

                // clear filter
                $('#clear-filter').on('click', function () {
                    $filterCheckboxes.prop('checked', false).change();
                    $levelCheckboxes.prop('checked', false).change();
                    FJS.setTemplate('#list-shops-template', true);
                });
                
                var setCategories = new Set(); 
                theme.mapCenter.forEach(function (mapItem) {
                    mapItem.category.forEach(function(category) {
                        setCategories.add(category)
                    })
                });

                var categoreis = [];

                Array.from(setCategories).forEach(function(element, index) {
                    var items = theme.mapCenter.filter(function (item) {
                       return item.category.includes(element)
                    });
                    categoreis.push({
                        name: element,
                        slug: element.replace(/\s+/g, '-').toLowerCase(),
                        items: items
                    });
                });

                // afterFilter callback
                var afterFilter = function (result, jQ) {
                    if (result.length > 0) {
                        $('#noresults').fadeOut();
                    } else {
                        $('#noresults').fadeIn();
                    }
                }
                
                var FJS = FilterJS(categoreis, '#list-shops', {
                    template: '#list-shops-template',
                    filter_on_init: false, // Default filter_on_init is false
                    search: {ele: '#autocomplite'},  // Search in all fields of JSON object.
                    prevText: 'gdfgfd',
                    nextText: 'gfdgfdg',
                    callbacks: {
                        afterFilter: afterFilter,
                    },
                    pagination: {
                        container: '.shop-pagination',
                        visiblePages: 5,

                        perPage: {
                            values: [100],
                            container: '#shop-per_page',

                        },
                    }
                });
                //window.FJS = FJS;
            }
        },
        /* ---------------------------------------------
                    magnificPopup
                --------------------------------------------- */
        calc: function () {
            let $target = $(".container-no-right-margin");
            if ($target.length) {
                update($target);
                $(window).on('resize', function () {
                    update($target);
                });

                function update($target) {
                    if ($(window).width() > 992) {
                        var width = $(".container").outerWidth() + $(".container .row").position().left - 20;
                        $target.css("width", width);
                    } else {
                        $target.css("width", '');
                    }
                }
            }
        },
        /* ---------------------------------------------
            Preloader
        --------------------------------------------- */
        preloader: function() {
            $body.removeClass("loading");
            $body.addClass("loaded");
        },
        /* ---------------------------------------------
            Preloader
        --------------------------------------------- */
        multiSelect: function() {
            $('.multiselect').multiselect({
                buttonContainer: '<div class="multiselect-wrapper" />',
                nonSelectedText: 'Wyświetl kategorie',
                buttonClass: '',
                templates: {
                    button: '<button type="button" class="multiselect dropdown-toggle" data-toggle="dropdown"><span class="multiselect-selected-text"></span></button><ul class="multiselect-results"></ul>',
                },
                buttonText: function(options, select) {
                    return this.nonSelectedText;
                },
                buttonTitle: function(options, select) {
                    return this.nonSelectedText;
                },
                onInitialized: function(options) {
                    var selectedOptions = $('.multiselect option:selected');
                    var selected = '';
                    selectedOptions.each(function () {
                        var label = ($(this).attr('label') !== undefined) ? $(this).attr('label') : $(this).html();

                        selected += '<li><a href="#">' + label + '</a></li>';
                    });
                    $(".multiselect-results").html(selected);
                },
                onChange: function(option, checked) {
                    if(checked) {
                        $(".multiselect-container").find("li").eq(option.index()).addClass("active");
                    }
                    else {
                        $(".multiselect-container").find("li").eq(option.index()).removeClass("active");
                    }
                    var selectedOptions = $('.multiselect option:selected');
                    var selected = '';
                    selectedOptions.each(function() {
                        var label = ($(this).attr('label') !== undefined) ? $(this).attr('label') : $(this).html();

                        selected += '<li><a href="#">' + label + '</a></li>';
                    });
                    $(".multiselect-results").html(selected);
                },
            });
        },
        /* ---------------------------------------------
            Sticky
        --------------------------------------------- */
        sticky: function() {
            $('.sticky').stickySidebar({
                topSpacing: 130,
                bottomSpacing: 100,
                containerSelector: false,
            });
            $('.section-home-cart .btn').mouseenter(function() {
                $('.section-home-cart').find(".cart-bg").addClass("kenburns");
            }).mouseleave(function() {
                $('.section-home-cart').find(".cart-bg").removeClass("kenburns");
            });
        },
        /* ---------------------------------------------
            Grid mansory
        --------------------------------------------- */
        gridMansory: function() {
            if (  $('.grid-mansory').length > 0){
                var $grid = $('.grid-mansory').isotope({
                    itemSelector: 'li',
                    percentPosition: false,
                });


                // layout Masonry after each image loads
                $('.grid-mansory').imagesLoaded( function() {
                    $grid.isotope('layout');
                });

                $('.filter-mansory').click(function(){
                    $('.filter-mansory').removeClass('active');
                    $(this).addClass('active');

                    var selector = $(this).attr('data-filter');
                    $grid.isotope({
                        filter: selector,
                        animationOptions: {
                            duration: 750,
                            easing: 'linear',
                            queue: false
                        }
                    });

                    return false;
                });
            }
        },
        
        /* ---------------------------------------------
            swiper
        --------------------------------------------- */
        swiper: function(){

            // swiper galleries
            if ( $('.swiper-gallery').length > 0 ) {
                $('.swiper-gallery').each(function(){
                    let swiperGalleryLength = $(this).find('.swiper-slide').length;
                    let swiperGallery = new Swiper(this, {
                        slidesPerView: 4,
                        spaceBetween: 15,
                        simulateTouch: true,
                        // touchRatio: 0,
                        loop: true,
                        slideToClickedSlide: true,
                        allowTouchMove: true,
                        loopAdditionalSlides: 10,
                        uniqueNavElements: false,
                        preventInteractionOnTransition: true,
                        speed: 1200,
                        navigation: {
                            nextEl: $(this).find('.swiper-pagination').find('.swiper-button-next'),
                            prevEl: $(this).find('.swiper-pagination').find('.swiper-button-prev'),
                        },
                        breakpoints: {
                            // when window width is <= 640px
                            430: {
                                slidesPerView: 1,
                                spaceBetween: 0,
                                loop: true,
                                centeredSlides: true,
                            },
                            768: {
                                slidesPerView: 1.5,
                                spaceBetween: 20,
                                centeredSlides: true,
                                loop: true,
                            },
                            992: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                                centeredSlides: true,
                                loop: true,
                            },
                            1300: {
                                centeredSlides: true,
                                slidesPerView: 3,
                                spaceBetween: 20,
                                loop: true,
                            },
                        }
                    });
                });
            }
            // swiper news
            if ( $('.swiper-news').length > 0 ) {
                $('.swiper-news').each(function(){
                    let swiperNewsLength = $(this).find('.swiper-slide').length;
                    let swiperNews = new Swiper(this, {
                        slidesPerView: 2,
                        spaceBetween: 100,
                        simulateTouch: true,
                        centeredSlides: true,
                        touchStartForcePreventDefault: true,
                        preventClicks: true,
                        preventClicksPropagation: true,
                        // touchRatio: 0,
                        loop: true,
                        slideToClickedSlide: true,
                        allowTouchMove: true,
                        loopAdditionalSlides: 10,
                        uniqueNavElements: false,
                        preventInteractionOnTransition: false,
                        speed: 1200,
                        navigation: {
                            nextEl: $('.swiper-news').find('.swiper-pagination').find('.swiper-button-next'),
                            prevEl: $('.swiper-news').find('.swiper-pagination').find('.swiper-button-prev'),
                        },
                        breakpoints: {
                            // when window width is <= 640px
                            430: {
                                autoHeight: true,
                                slidesPerView: 1,
                                spaceBetween: 0,
                                loop: true,
                            },
                            768: {
                                autoHeight: true,
                                slidesPerView: 1.2,
                                spaceBetween: 20,
                                loop: true,
                            },
                            992: {
                                autoHeight: true,
                                slidesPerView: 1.5,
                                spaceBetween: 20,
                                loop: true,
                            },
                            1300: {
                                autoHeight: true,
                                slidesPerView: 1.8,
                                spaceBetween: 50,
                                loop: true,
                            },
                        }
                    });
                });
            }
            // swiper about
            if ( $('#swiper-about').length > 0 ) {
                let swiperAboutLength = $('#swiper-about .swiper-slide').length;
                let swiperAbout = new Swiper('#swiper-about', {
                    slidesPerView: 1.5,
                    spaceBetween: 20,
                    simulateTouch: true,
                    // touchRatio: 0,
                    loop: true,
                    slideToClickedSlide: true,
                    centeredSlides: true,
                    allowTouchMove: true,
                    loopAdditionalSlides: 10,
                    uniqueNavElements: false,
                    preventInteractionOnTransition: true,
                    speed: 1200,
                    navigation: {
                        prevEl: '#about-swiper-prev',
                        //prevEl: $(this).closest('.section-swiper').find('.swiper-blog-prev')[0],
                    },
                    breakpoints: {
                        // when window width is <= 640px
                        640: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                        loop: swiperAboutLength > 2,
                        },
                    }
                });
            }
            // swiper slider
            if ($('#swiper-main').length > 0) {
                let swiperMain = new Swiper('#swiper-main', {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    /*loop: true,
                    loopedSlides: 1,*/
                    speed: 1700,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    navigation: {
                        nextEl: $('.section-home-slider').find('.swiper-pagination').find('.swiper-button-next'),
                        prevEl: $('.section-home-slider').find('.swiper-pagination').find('.swiper-button-prev')
                    },
                    pagination: {
                        el: $('.section-home-slider').find('.swiper-pagination').find('.swiper-pagination-bullets'),
                        clickable: true
                    },
                });
            }
        },

        /* ---------------------------------------------
            matchHeight
        --------------------------------------------- */
        matchHeight: function() {
            if ($('.promo-item').length > 0) {
                $('.promo-item .promo-text').matchHeight({
                    byRow: false,
                    property: 'height',
                    target: null,
                    remove: false
                });
            }
        },
        
        /* ---------------------------------------------
            Cookie
        --------------------------------------------- */
        cookie: function () {
            let $cookieDiv = $("#cookie");
            let cookie_delay = 3000;
            if ( !Cookies.get('cookie-notyfication') ) {
                setTimeout(function () {
                    $cookieDiv.addClass("show");
                }, cookie_delay);
                $('#cookie-close').on('click', function () {
                    Cookies.set('cookie-notyfication', 'accept', { expires: 365 });
                    $cookieDiv.removeClass("show");
                });
            } 
        },
        
        /* ---------------------------------------------
            Aos
        --------------------------------------------- */
        aos: function() {
            AOS.init({
                // Global settings
                //disable: $(window).width() < 1199, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
                //startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
                initClassName: 'aos-init', // class applied after initialization
                animatedClassName: 'aos-animate', // class applied on animation
                useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
              
                // Settings that can be overriden on per-element basis, by `data-aos-*` attributes:
                offset: 250, // offset (in px) from the original trigger point
                delay: 400, // values from 0 to 3000, with step 50ms
                duration: 450, // values from 0 to 3000, with step 50ms
                easing: 'ease', // default easing for AOS animations
                once: true, // whether animation should happen only once - while scrolling down
                mirror: false, // whether elements should animate out while scrolling past them
                anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
            });

            window.addEventListener('load', AOS.refresh);
        },

        /* ---------------------------------------------
            Form validation
        --------------------------------------------- */
        formValidation: function () {
            //Global validation
            window.Parsley.on('field:error', function() {
                this.$element.addClass('form-control-danger');
            });
                
            window.Parsley.on('field:success', function() {
                this.$element.removeClass('form-control-danger');
                this.$element.addClass('form-control-success');
            });

            // Form gruop heleper classes
            $('form[novalidate]').parsley({
                successClass: "has-success",
                errorClass: "has-danger",
                classHandler: function(el) {
                    return el.$element.closest(".form-group");
                },
                errorsWrapper: '<ul class="parslay-danger"></ul>',
                errorElem: '<li></li>'
            });

            $('form[novalidate]').on('submit', function( evt){
                let form = $(this);
                form.parsley().validate();
                
                if (form.parsley().isValid()){
                    if ( form.attr('data-prevent') === 'true' ){
                        evt.preventDefault();
                    }

                    if ( form.attr('data-modal-show')){
                        var modal = form.attr('data-modal-show');
                        form[0].reset();
                        $(modal + "").modal('show')
                    }
                }
            });
        },

        /* ---------------------------------------------
            magnificPopup
        --------------------------------------------- */
        magnificPopup: function() {
            let $mfpGallery = $('.mfp-gallery');
            if ($mfpGallery.length) {
                $mfpGallery.each(function() { // the containers for all your galleries
                    $(this).magnificPopup({
                        delegate: 'a:not(.disable-mfp)', // the selector for gallery item
                        type: 'image',
                        //mainClass: 'mfp-with-zoom', // this class is for CSS animation below
                        mainClass: 'mfp-zoom-in',
                        showCloseBtn: true,
                        closeBtnInside: true,
                        closeOnContentClick: true,
                        closeMarkup: '<span class="mfp-close"><span class="icon-cross-out"></span></span>',
                        gallery: {
                            enabled: true,
                            arrowMarkup: '<span title="%title%" class="mfp-arrow mfp-arrow-%dir%"></span>', // markup of an arrow button
                        },
                        image: {
                            // titleSrc: function(item) {
                            //     return item.el.find('.overlay-content').html();
                            // }
                        },
                        removalDelay: 300, //delay removal by X to allow out-animation
                        callbacks: {
                            open: function() {
                                //overwrite default prev + next function. Add timeout for css3 crossfade animation
                                $.magnificPopup.instance.next = function() {
                                    var self = this;
                                    self.wrap.removeClass('mfp-image-loaded');
                                    setTimeout(function() { $.magnificPopup.proto.next.call(self); }, 120);
                                }
                                $.magnificPopup.instance.prev = function() {
                                    var self = this;
                                    self.wrap.removeClass('mfp-image-loaded');
                                    setTimeout(function() { $.magnificPopup.proto.prev.call(self); }, 120);
                                }
                            },
                            imageLoadComplete: function() {
                                var self = this;
                                setTimeout(function() { self.wrap.addClass('mfp-image-loaded'); }, 16);
                            }
                        }
                    });
                });
            };

            $('.popup-video').magnificPopup({
                disableOn: 700,
                type: 'iframe',
                mainClass: 'mfp-fade',
                removalDelay: 160,
                preloader: false,
                fixedContentPos: false
            });

            $('[data-lightbox="inline"]').magnificPopup({
                type:'inline',
            });
        },



        /* ---------------------------------------------
            Inner links
        --------------------------------------------- */
        innerLinks: function(){
            if($('.inner-link').length){
                $('.inner-link').on('click', function (e) {
                    console.log('click')
                    e.preventDefault();
                    var href = $(this).attr('href');
                    var speed = parseInt( $(this).attr('data-speed'), 10) || 600;
                    var offset = $(this).attr('data-offset') || 90;
                    $('html, body').animate({
                        scrollTop: $(href).offset().top - offset
                    }, speed);
                    return false;
                });
            }
        },

        /* ---------------------------------------------
            Header, main menu
        --------------------------------------------- */
        header: function () {
            let $offcanvasMenu= $('#offcanvas');
            let $toggleMenu = $("#toggle-menu");
            let scroll_amount = 200;
            let $search = $("#search");
            
            //menu
            $toggleMenu.click(function(){
                $(this).toggleClass("is-active");
                $offcanvasMenu.toggleClass('active');
                $('body').toggleClass("menu-is-active");
            });

            $offcanvasMenu.find("#closeOffcanvas").click(function() {
                $toggleMenu.toggleClass("is-active");
                $offcanvasMenu.toggleClass('active');
                $('body').toggleClass("menu-is-active");
            });

            // scroll event
            if ( $window.scrollTop() > scroll_amount ){
                $body.addClass("body-scroll");
            }
            $window.scroll(function() {
                if ( $(this).scrollTop() > 30 ){
                    $body.addClass("page-scroll");
                } else{
                    $body.removeClass("page-scroll");
                }
            });

            $search.find(".close").click(function () {
                $search.removeClass("active");
                setTimeout(function() {
                    $body.removeClass("modal-open");
                }, 400);

            });

            $(document).on('keyup',function(evt) {
                if (evt.keyCode === 27) {
                    $search.removeClass("active");
                    setTimeout(function() {
                        $body.removeClass("modal-open");
                    }, 400);
                }
            });


            $("#searchFormBtn").click(function(e){
                e.preventDefault();
                if ($search.hasClass('active')) {
                    $search.removeClass("active");
                    setTimeout(function() {
                        $body.removeClass("modal-open");
                    }, 400);
                }
                else {
                    $search.addClass('active');
                    $search.find("#search_input").focus();
                    $body.addClass("modal-open");
                }
            });
        },
    };
})(jQuery);





